import Select, { components } from "react-select";
import classNames from "./../utils/classNames";

export default function TaxSelect({ value, list, update, disabled, country, horizontal = false, platformStyles }) {
  return (
    <label className={classNames("flex flex-shrink", horizontal ? "flex-row gap-5 items-center my-1" : "flex-col flex-1")}>
      <span className="flex flex-row text-xs text-gray-700">Tax</span>
      <div className={disabled ? "relative cursor-help group" : ""}>
        <Select
          className="w-32 text-sm xl:w-48"
          classNamePrefix=""
          value={value}
          options={list}
          isDisabled={disabled}
          placeholder={country === "US" ? "No Tax" : "Tax"}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: platformStyles["--platformlight"],
              primary50: platformStyles["--platformlight"],
              primary: platformStyles["--platform"],
            },
            spacing: {
              ...theme.spacing,
              baseUnit: 3,
            },
          })}
          onChange={(selectedOption) => {
            update(selectedOption.value);
          }}
        />
        {disabled && (
          <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% left-1 mt-2 whitespace-nowrap group-hover:opacity-100">
            {country === "US" ? "There are no taxable line items" : "Taxes are currently set per line item"}
            <span className="absolute top-0 left-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
          </span>
        )}
      </div>
    </label>
  );
}
