/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Puff } from "svg-loaders-react";

export default function AlertLoading({ open, h1, setClose }) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end justify-end p-6 px-4 py-6 pointer-events-none"
      >
        {open ? (
          <div className="fixed top-0 left-0 z-10 w-screen h-screen pointer-events-auto bg-tranparent"></div>
        ) : null}
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={open}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="opacity-0 translate-y-0 translate-x-2"
          enterTo="translate-y-0 opacity-100 translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="z-20 w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto outline outline-gray-50">
            <div className="p-4">
              <div className="flex items-start items-center">
                <div className="flex-shrink-0">
                  <Puff stroke="#059669" strokeOpacity="1" aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{h1}</p>
                </div>
                <div className="flex flex-shrink-0 ml-4">
                  <button
                    className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-none"
                    onClick={() => {
                      setClose();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
