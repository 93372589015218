import React from "react";
import { Fragment } from "react";
import Select from "react-select";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Puff } from "svg-loaders-react";
import { CheckCircleIcon } from "@heroicons/react/outline";

import classNames from "./../utils/classNames";

class Attachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentsToAttach: [],
      attachTo: "invoice",
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open && this.props.hubspotAttachments === null) {
      await this.props.getHubspotAttachments();
    }
  }

  setClose = () => {
    this.props.setClose();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.attachTo === "invoice") {
      const attachments = [...this.props.invoice.attachments, ...this.state.attachmentsToAttach];
      this.props.updateInvoice(this.props.invoiceIndex, "attachments", attachments);
    }

    if (this.state.attachTo === "all-invoices") {
      this.props.addAttachmentsToAllInvoices(this.state.attachmentsToAttach);
    }

    this.setState({ attachmentsToAttach: [] });
  };

  handleRemove = (item) => {
    let attachments;

    if (!item.id) {
      attachments = this.props.invoice.attachments.filter(
        (attachment) => attachment.hubspot_id != item.hubspot_id
      );
    } else {
      attachments = this.props.invoice.attachments.map((attachment) =>
        item.id == attachment.id ? { ...attachment, status: "TO-DELETE" } : attachment
      );
    }

    this.props.updateInvoice(this.props.invoiceIndex, "attachments", attachments);
  };

  handleReupload = async (item) => {
    const attachments = this.props.invoice.attachments.map((attachment) =>
      item.id == attachment.id ? { ...attachment, status: "SYNCING" } : attachment
    );
    this.props.updateInvoice(this.props.invoiceIndex, "attachments", attachments);
    await this.props.uploadAttachment(item);
    this.setClose();
  }

  handleRedelete = async (item) => {
    const attachments = this.props.invoice.attachments.map((attachment) =>
      item.id == attachment.id ? { ...attachment, status: "SYNCING" } : attachment
    );
    this.props.updateInvoice(this.props.invoiceIndex, "attachments", attachments);
    await this.props.deleteAttachment(item);
    this.setClose();
  }

  onAttachmentsToAttachChange = (e) => {
    const checked = e.target.checked;

    let newAttachmentsToAttach;
    const attachmentsToAttach = this.state.attachmentsToAttach.map((a) => ({ ...a }));

    if (checked) {
      newAttachmentsToAttach = [
        ...attachmentsToAttach,
        { hubspot_id: e.target.value, filename: e.target.name },
      ];
    } else {
      newAttachmentsToAttach = attachmentsToAttach.filter((a) => a.hubspot_id !== e.target.value);
    }

    this.setState({ attachmentsToAttach: newAttachmentsToAttach });
  };

  onAttachToChange = (e) => {
    this.setState({ attachTo: e.target.value });
  };

  statusIcon = (status) => {
    console.log(status);
    switch (status) {
      case "UPLOAD-FAILED":
        return <XIcon className="w-5 h-5 text-red-700" />;
      default:
        return <CheckCircleIcon className="w-5 h-5" />;
    }
  };

  attachedListItem = (item) => {
    const notSynced = item.status !== "SYNCED";
    const syncedToXero = !!item.xero_id && item.status === "SYNCED";
    const hubspotAttachmentsFetched = this.props.hubspotAttachments !== null;
    const hubspotAttachmentExists = this.props.hubspotAttachments?.find(
      (a) => a.id === item.hubspot_id
    );
    const error = notSynced && hubspotAttachmentsFetched && !hubspotAttachmentExists;
    
    return (
      <li
        key={item?.hubspot_id || item?.xero_id || item?.qbo_id}
        className="flex gap-3 px-4 py-2 text-sm hover:bg-gray-50"
      >
        {this.statusIcon(item.status)}

        <div className={classNames("flex-1", item.status === "UPLOAD-FAILED" && "text-red-700")}>
          {item.filename}
        </div>
        <div className="relative group">
          <button
            className={classNames(
              syncedToXero ? "hidden" : "",
              error ? "text-red-400" : "",
              "relative px-2 hover:text-red-900 text-platform text-xs text-red-700"
            )}
            onClick={() => this.handleRemove(item)}
            disabled={syncedToXero}
          >
            Remove
          </button>

          {item.status === "UPLOAD-FAILED" &&
          <button className="px-2 text-xs text-platform" onClick={() => this.handleReupload(item)}>
            Try again
          </button>
          }
          {item.status === "DELETE-FAILED" && (
            <button
              className="px-2 text-xs text-platform"
              onClick={() => this.handleRedelete(item)}
            >
              Try again
            </button>
          )}
        </div>
      </li>
    );
  };

  render() {
    const attached = this.props.invoice.attachments;

    const unattachedHubspotAttachments = this.props.hubspotAttachments?.filter(
      (a) => !this.props.invoice.attachments.find((i) => i.hubspot_id === a.id)
    );

    const submitDisabled = this.state.attachmentsToAttach?.length < 1;

    return (
      <>
        <Transition.Root show={this.props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 overflow-hidden"
            onClose={this.setClose}
            style={this.props.platformStyles}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 transition-opacity bg-white bg-opacity-75"> </div>
              </Transition.Child>
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                      <div className="flex flex-col flex-shrink min-h-0 py-6 ">
                        <div className="px-4 sm:px-6 ">
                          <div className="flex items-start justify-between ">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Files
                              <div className="text-xs text-grey">
                                Include files with your invoice, such as Purchase Orders or
                                Contracts.
                              </div>
                            </Dialog.Title>
                            <div className="flex items-center ml-3 h-7">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                                onClick={this.setClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 overflow-y-scroll">
                        {!!attached.length ? (
                          <div className="px-6 mt-6 sm:px-6">
                            <h2 className="mb-4 font-semibold text-center text-gray-900">
                              Attached files
                            </h2>
                            <ul className="divide-y divide-gray-200">
                              {attached
                                .filter((a) => a.status !== "TO-DELETE")
                                .map((a) => this.attachedListItem(a))}
                            </ul>
                          </div>
                        ) : (
                          <div className="px-6 mt-6 sm:px-6">
                            <div className="py-1 text-center">
                              No files attached
                            </div>
                          </div>
                        )}
                        {!this.props.isLocked && (
                          <div className="flex flex-col justify-center gap-3 p-5 m-6 border-2 border-gray-200 border-dashed rounded-3xl">
                            {!this.props.hubspotAttachmentsLoading ? (
                              <>
                                {unattachedHubspotAttachments?.length > 0 ? (
                                  <div>
                                    <h2 className="mb-4 font-semibold text-center text-gray-900">
                                      {!!attached.length ? "Attach more files" : "Attach files"}
                                    </h2>

                                    <div className="flex flex-col gap-3">
                                      {unattachedHubspotAttachments?.length > 0 ? (
                                        <div className="text-xs text-grey">
                                          Showing files attached to your HubSpot deal. Save progress
                                          and attach files to your deal to add more.
                                        </div>
                                      ) : (
                                        <div className="text-xs text-grey">
                                          Showing files attached to your HubSpot deal.
                                        </div>
                                      )}
                                      <form onSubmit={this.handleSubmit}>
                                        <fieldset className="divide-y divide-gray-200">
                                          {unattachedHubspotAttachments.map((attachment) => {
                                            let attachmentForbidden =
                                              attachment.errors.fileSize ||
                                              attachment.errors.fileType;
                                            // TO DO - file type restrictions
                                            return (
                                              <label
                                                key={attachment.id}
                                                className={classNames(
                                                  attachmentForbidden &&
                                                    "opacity-50 cursor-not-allowed",
                                                  this.state.attachmentsToAttach.find(
                                                    (a) => a.hubspot_id === attachment.id
                                                  ) && "bg-gray-50",
                                                  "flex gap-6 px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                                )}
                                              >
                                                <input
                                                  type="checkbox"
                                                  value={attachment.id}
                                                  name={attachment.name}
                                                  onChange={this.onAttachmentsToAttachChange}
                                                  disabled={attachmentForbidden}
                                                />
                                                <div className="flex flex-col text-sm">
                                                  {attachment.name}
                                                  <span className="text-xs text-gray-500">
                                                    .{attachment.extension}{" "}
                                                    <span>
                                                      {attachment.size < 1024 * 1024
                                                        ? `${(attachment.size / 1024).toFixed(2)}KB`
                                                        : `${(
                                                            attachment.size /
                                                            (1024 * 1024)
                                                          ).toFixed(2)}MB`}
                                                    </span>
                                                    {attachment.errors.fileSize &&
                                                      " - File size exceeds limit"}
                                                    {attachment.errors.fileType &&
                                                      " - Invalid file type"}
                                                  </span>
                                                </div>
                                              </label>
                                            );
                                          })}
                                        </fieldset>

                                        <div className="py-4">
                                          <Select
                                            name="attach-to"
                                            id="attach-to"
                                            className="w-full text-sm"
                                            value={{
                                              value: this.state.attachTo,
                                              label:
                                                this.state.attachTo === "invoice"
                                                  ? "Attach files to this invoice"
                                                  : "Attach files to all invoices",
                                            }}
                                            onChange={(selectedOption) =>
                                              this.onAttachToChange({
                                                target: { value: selectedOption.value },
                                              })
                                            }
                                            options={[
                                              {
                                                value: "invoice",
                                                label: "Attach files to this invoice",
                                              },
                                              {
                                                value: "all-invoices",
                                                label: "Attach files to all invoices",
                                              },
                                            ]}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25:
                                                  this.props.platformStyles["--platformlight"],
                                                primary50:
                                                  this.props.platformStyles["--platformlight"],
                                                primary: this.props.platformStyles["--platform"],
                                              },
                                              spacing: {
                                                ...theme.spacing,
                                                baseUnit: 3,
                                              },
                                            })}
                                          />
                                        </div>
                                        <button
                                          className={classNames(
                                            submitDisabled
                                              ? "bg-gray-300 border-gray-300"
                                              : "bg-platform border-platform",
                                            "block  px-6 py-3 text-white border-2 rounded-md mx-auto"
                                          )}
                                          type="submit"
                                          disabled={submitDisabled}
                                        >
                                          Attach files
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="py-1 text-center">
                                    No {attached.length ? "more " : null}files to add
                                    <div className="text-xs text-grey">
                                      Files must be attached to your HubSpot deal first. Save
                                      progress and attach files to your deal to add more.
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="py-6 mx-auto text-center">
                                <Puff
                                  stroke={this.props.platformStyles["--platform"]}
                                  strokeOpacity="1"
                                  aria-hidden="true"
                                  className="mx-auto"
                                />
                                <div class="text-sm mt-2">
                                  Fetching attachments from your HubSpot deal...
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="flex justify-end flex-shrink-0 px-4 py-4">
                        <button
                          type="button"
                          className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                          onClick={() => this.props.setClose()}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default Attachments;
